<template>
  <layout name="HomeLayout">
    <div class="Home">
      <section class="uk-section uk-section-small">
        <div class="uk-container">
          <div class="uk-grid-medium uk-child-width-1-1" uk-grid>
            <div class="uk-text-center">
              <ul class="uk-breadcrumb uk-flex-center uk-margin-remove">
                <li><a href="index.html">Home</a></li>
                <li><span>FAQ</span></li>
              </ul>
              <h1 class="uk-margin-small-top uk-margin-remove-bottom">FAQ</h1></div>
            <div>
              <div class="uk-grid-medium" uk-grid>
                <section class="uk-width-1-1 uk-width-expand@m">
                  <article
                    class="uk-card uk-card-default uk-card-small uk-card-body uk-article tm-ignore-container">
                    <ul class="uk-list-divider uk-list-large" uk-accordion="multiple: true">
                      <li><a class="uk-accordion-title" href="#">this is question ?</a>
                        <div class="uk-accordion-content"><p>
                          sefsdf sdf sdf sdf sdf
                        </p></div>
                      </li>
                      <li><a class="uk-accordion-title" href="#">this is question ?</a>
                        <div class="uk-accordion-content"><p>
                          sefsdf sdf sdf sdf sdf
                        </p></div>
                      </li>
                      <li><a class="uk-accordion-title" href="#">this is question ?</a>
                        <div class="uk-accordion-content"><p>
                          sefsdf sdf sdf sdf sdf
                        </p></div>
                      </li>

                    </ul>
                  </article>
                </section>
                <aside class="uk-width-1-4 uk-visible@m tm-aside-column">
                  <section class="uk-card uk-card-default uk-card-small"
                           uk-sticky="offset: 90; bottom: true;">
                    <nav>
                      <ul class="uk-nav uk-nav-default tm-nav">
                        <li><a href="#">About</a></li>
                        <li><a href="#">Contacts</a></li>
                        <li><a href="#">Blog</a></li>
                        <li >
                          <router-link title="News" tag="a" :to="{name: 'news'}"> News
                          </router-link>
                        </li>
                        <li class="uk-active">
                          <router-link title="Faq" tag="a" :to="{name: 'faq'}"> FAQ
                          </router-link>
                        </li>
                      </ul>
                    </nav>
                  </section>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </layout>
</template>

<script scoped>
  import Layout from '../../layouts/Layout'

  export default {
    name: 'Home',
    components: {
      Layout
    }
  }
</script>

<style scoped>
</style>
